import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useInView } from 'react-intersection-observer'
import FlagTeaser from "../../Containers/FlagTeaser/FlagTeaser"
import PageTransitionLink from "../PageTransition/PageTransitionLink"
import "./TeaserItem.scss"

const TeaserItem = (props) => {
  const { title, png, slug, classes, slitScanHorizontal } = props
  const [inViewRef, isInView] = useInView({
    threshold: 0.33,
    triggerOnce: true
  })

  return (
    <div
      ref={inViewRef}
      className={classNames("teaser-item col-lg-6", classes)}
    >
      <div className={classNames("animation-wrap", isInView && "show")}>
        <PageTransitionLink to={`/${slug}`}>
          <div className="item-wrap d-flex flex-column-reverse flex-md-row">
            {/* <ImageDistortion svg={image} /> */}
            {/* <div
              className="image order-2"
              dangerouslySetInnerHTML={{ __html: image }}
            /> */}
            <div className="image order-2">
              <FlagTeaser
                key={title}
                image={png}
                slitScanHorizontal={slitScanHorizontal}
              />
            </div>
            <div className="left-col d-flex justify-content-between order-3 order-md-1">
              <h2 className="title">{title}</h2>
            </div>
          </div>
        </PageTransitionLink>
      </div>
    </div>
  )
}

TeaserItem.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  classes: PropTypes.string,
  slitScanHorizontal: PropTypes.bool
}

TeaserItem.defaultProps = {
  title: "",
  slug: "",
  classes: "",
  slitScanHorizontal: false
}


export default TeaserItem

import React from "react"
import TeaserItem from "UI/TeaserItem/TeaserItem.jsx"

import "./Teaser.scss"

const Teaser = ({content}) => {
  return (
    <div className="teaser container pt-2" id="index">
      <div className="row flex-wrap">
        <div className="section-title col-lg-6">
          <h2 className="h1">Flag Index</h2>
        </div>
        {content.length > 0 && (
          content.map((item) => (
            <TeaserItem
              key={item.node.title}
              title={item.node.title}
              png={item.node.flagPng}
              slitScanHorizontal={item.node.slitScanHorizontal}
              slug={item.node.slug}
              classes="pb-lg-7"
            />
          ))
        )}
      </div>
    </div>
  )
}

export default Teaser

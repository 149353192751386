import React, {useState} from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
// import classnames from "classnames"

// import "./TurbulenceFont.scss"

const TurbulenceFont = () => {
  const [scrollFactor, setScrollFactor] = useState(0)
  const intViewportHeight = typeof window !== `undefined` ? window.innerHeight : 0
  const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2

  if (typeof window !== `undefined`) {
    useScrollPosition(
      ({ currPos }) => {
        if(intViewportHeight + currPos.y > 0){
          const scrollPosition = currPos.y
          setScrollFactor(map(scrollPosition, 0, intViewportHeight, 0, 1) * (scrollPosition < 0 ? -1 : 1))
        }
      },
      [scrollFactor],
      null,
      false,
      50
    )
  }

  return (
    <svg>
      <filter id="filter">
        <feTurbulence
          type="turbulence"
          // baseFrequency="0.002 0.008"
          baseFrequency={`${scrollFactor * 0.002} ${scrollFactor * 0.008}`}
          numOctaves={parseInt(50 * scrollFactor, 10)}
          // seed={20 * scrollFactor}
          seed={50 * scrollFactor}
          stitchTiles="stitch"
          result="turbulence"
        />
        <feColorMatrix
          type="saturate"
          values={30 * scrollFactor}
          in="turbulence"
          result="colormatrix"
        />
        <feColorMatrix
          type="matrix"
          values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 150 -15"
          in="colormatrix"
          result="colormatrix1"
        />
        <feComposite
          in="SourceGraphic"
          in2="colormatrix1"
          operator="in"
          result="composite"
        />
        <feDisplacementMap
          in="SourceGraphic"
          in2="colormatrix1"
          scale="15"
          xChannelSelector="R"
          yChannelSelector="A"
          result="displacementMap"
        />
      </filter>
    </svg>
  )
}

export default TurbulenceFont

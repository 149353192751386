/* eslint-disable */
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as THREE from "three"
import TurbulenceFont from "UI/TurbulenceFont/TurbulenceFont.jsx"

import "./ThreeFlag.scss"

const ThreeFlag = () => {
  const { useRef, useLayoutEffect } = React;
  const mount = useRef(null);

  const data = useStaticQuery(graphql`
    query ThreeFlagQuery {
      allCountriesJson {
        edges {
          node {
            id
            slug
            title
            flagPng {
              publicURL
            }
            inThreeCanvas
          }
        }
      }
    }
  `)

  // const handleOnLinkClick = (URL) => {
  //   if (typeof window !== `undefined`) {
  //     window.location.assign(URL);
  //   }
  // }

  // //----------------------------------------------------------------- RANDOM Function
  // const mathRandom = (num = 8) => {
  //   var numValue = - Math.random() * num + Math.random() * num;
  //   return numValue;
  // };

  useLayoutEffect(() => {
    /*
    * Cloth Simulation using a relaxed constraints solver
    */

    // Suggested Readings

    // Advanced Character Physics by Thomas Jakobsen Character
    // http://freespace.virgin.net/hugo.elias/models/m_cloth.htm
    // http://en.wikipedia.org/wiki/Cloth_modeling
    // http://cg.alexandra.dk/tag/spring-mass-system/
    // Real-time Cloth Animation http://www.darwin3d.com/gamedev/articles/col0599.pdf

    const params = {
      enableWind: true,
      showBall: false,
      useBallPhysics: false,
      pins: {
        left: true,
        bottom: false
      },
      windStrengthAmplifyer: 1500,
      rotationSpeed: 0.0005
    };

    const DAMPING = 0.05;
    const DRAG = 1 - DAMPING;
    const MASS = 0.2;
    const restDistance = 50;
    const xSegs = 9;
    const ySegs = 6;
    const clothFunction = plane(restDistance * xSegs, restDistance * ySegs);
    const cloth = new Cloth(xSegs, ySegs);
    const GRAVITY = 981 * 1.4;
    const gravity = new THREE.Vector3( 0, - GRAVITY, 0 ).multiplyScalar( MASS );
    const TIMESTEP = 18 / 1000;
    const TIMESTEP_SQ = TIMESTEP * TIMESTEP;
    let pins = [];
    const windForce = new THREE.Vector3( 0, 0, 0 );
    const tmpForce = new THREE.Vector3();
    let lastTime;

    let windowWidth = 0;
    let windowHeight = 0;

    if (typeof window !== `undefined`) {
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;

      if(windowWidth < 1024){
        params.rotationSpeed = 0.002;
      }
    }

    // MOUSE function

    let mouse = new THREE.Vector2(), INTERSECTED;

    function onMouseMove(event) {
      event.preventDefault();
      mouse.x = (event.clientX / windowWidth) * 2 - 1;
      mouse.y = -(event.clientY / windowHeight) * 2 + 1;
    };

    window.addEventListener('mousemove', onMouseMove, false);


    function plane(width, height) {
      return function (u, v, target) {
        const x = (u - 0.5) * width;
        const y = (v + 0.5) * height;
        const z = 0;

        target.set(x, y, z);
      };
    }

    function Particle( x, y, z, mass ) {
      this.position = new THREE.Vector3();
      this.previous = new THREE.Vector3();
      this.original = new THREE.Vector3();
      this.a = new THREE.Vector3( 0, 0, 0 ); // acceleration
      this.mass = mass;
      this.invMass = 1 / mass;
      this.tmp = new THREE.Vector3();
      this.tmp2 = new THREE.Vector3();

      // init
      clothFunction( x, y, this.position ); // position
      clothFunction( x, y, this.previous ); // previous
      clothFunction( x, y, this.original );
    }

    // Force -> Acceleration
    Particle.prototype.addForce = function ( force ) {
      this.a.add(
        this.tmp2.copy( force ).multiplyScalar( this.invMass )
      );
    };

    // Performs Verlet integration
    Particle.prototype.integrate = function ( timesq ) {
      const newPos = this.tmp.subVectors( this.position, this.previous );
      newPos.multiplyScalar( DRAG ).add( this.position );
      newPos.add( this.a.multiplyScalar( timesq ) );
      this.tmp = this.previous;
      this.previous = this.position;
      this.position = newPos;
      this.a.set( 0, 0, 0 );
    };

    const diff = new THREE.Vector3();

    const satisfyConstraints = ( p1, p2, distance ) => {
      diff.subVectors( p2.position, p1.position );
      const currentDist = diff.length();
      if ( currentDist === 0 ) return; // prevents division by 0
      const correction = diff.multiplyScalar( 1 - distance / currentDist );
      const correctionHalf = correction.multiplyScalar( 0.5 );
      p1.position.add( correctionHalf );
      p2.position.sub( correctionHalf );
    }

    function Cloth(w, h) {
      w = w || 10;
      h = h || 10;
      this.w = w;
      this.h = h;
      const particles = [];
      const constraints = [];
      let u; let v;

      // Create particles
      for ( v = 0; v <= h; v ++ ) {
        for ( u = 0; u <= w; u ++ ) {
          particles.push(
            new Particle( u / w, v / h, 0, MASS )
          );
        }
      }

      // Structural
      for ( v = 0; v < h; v ++ ) {
        for ( u = 0; u < w; u ++ ) {
          constraints.push( [
            particles[ index( u, v ) ],
            particles[ index( u, v + 1 ) ],
            restDistance
          ] );
          constraints.push([
            particles[ index( u, v ) ],
            particles[ index( u + 1, v ) ],
            restDistance
          ]);
        }
      }

      for (u = w, v = 0; v < h; v ++) {
        constraints.push([
          particles[index(u, v)],
          particles[index(u, v + 1)],
          restDistance
        ]);
      }

      for (v = h, u = 0; u < w; u ++) {
        constraints.push([
          particles[index(u, v)],
          particles[index(u + 1, v)],
          restDistance
        ]);
      }

      this.particles = particles;
      this.constraints = constraints;

      function index(u, v) {
        return u + v * ( w + 1 );
      }

      this.index = index;
    }

    const simulate = (time) => {
      if (! lastTime) {
        lastTime = time;
        return;
      }

      let i; let j; let il; let particles; let particle; let constraints; let constraint;

      // Aerodynamics forces
      if (params.enableWind) {
        let indx;
        const normal = new THREE.Vector3();
        const indices = clothGeometry.index;
        const normals = clothGeometry.attributes.normal;
        particles = cloth.particles;
        

        for (i = 0, il = indices.count; i < il; i += 3) {
          for (j = 0; j < 3; j ++) {
            indx = indices.getX(i + j);
            normal.fromBufferAttribute(normals, indx);
            tmpForce.copy(normal).normalize().multiplyScalar(normal.dot(windForce));
            particles[ indx ].addForce(tmpForce);
          }
        }
      }

      for (particles = cloth.particles, i = 0, il = particles.length; i < il; i ++) {
        particle = particles[ i ];
        particle.addForce( gravity );
        particle.integrate( TIMESTEP_SQ );
      }

      // Start Constraints
      constraints = cloth.constraints;
      il = constraints.length;

      for (i = 0; i < il; i ++) {
        constraint = constraints[i];
        satisfyConstraints(constraint[0], constraint[1], constraint[2]);
      }

      // Floor Constraints
      for ( particles = cloth.particles, i = 0, il = particles.length; i < il; i ++ ) {
        particle = particles[i];
        var pos = particle.position;
        if (pos.y < - 250) {
          pos.y = - 250;
        }
      }

      // Pin Constraints
      for (i = 0, il = pins.length; i < il; i ++) {
        const xy = pins[i];
        const p = particles[xy];
        p.position.copy(p.original);
        p.previous.copy(p.original);
      }
    }

    // set pin constraints
    const pinsFormation = [];
    pins = [];
    pinsFormation.push(pins);
    const setPins = [];

    if(params.pins){
      if(params.pins.top){
        // pins on the top side
        for(let i = 0; i <= xSegs + 1; i++){
          const newPin = i;
          setPins.push(newPin);
        }
      }
      if(params.pins.right){
        // pins on the right side
        for(let i = 0; i <= ySegs; i++){
          const newPin = (i * (xSegs + 1)) + xSegs;
          setPins.push(newPin);
        }
      }
      if(params.pins.bottom){
        // pins on the bottom side
        for(let i = 0; i <= xSegs; i++){
          const newPin = ySegs * (xSegs + 1) + i;
          setPins.push(newPin);
        }
      }
      if(params.pins.left){
        // pins on the left side
        for(let i = 0; i <= ySegs; i++){
          const newPin = i * (xSegs + 1);
          setPins.push(newPin);
        }
      }
    } else {
      // pins on the left side
      for(let i = 0; i <= ySegs; i++){
        const newPin = i * (xSegs + 1);
        setPins.push(newPin);
      }
      // pins on the bottom
      for(let i = 0; i <= xSegs; i++){
        const newPin = ySegs * (xSegs + 1) + i;
        setPins.push(newPin);
      }
    }
    pins = setPins;
    pinsFormation.push(pins);

    let camera; let scene; let renderer; let frameId;
    let clothGeometry; let object;
    const objects = []; const cylinders = []; 
    
    const availableFlags = data.allCountriesJson.edges.filter(edge => edge.node.inThreeCanvas === true).length 
    const amountObjects = windowWidth > 1024 ? availableFlags : Math.floor(availableFlags/4 * 3);

    // scene
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xEEEEEE);

    // FOG background
    var setcolor = 0xEEEEEE;

    scene.background = new THREE.Color(setcolor);
    scene.fog = new THREE.Fog(setcolor, 3500, 6000);

    // camera
    camera = new THREE.PerspectiveCamera(35, windowWidth / windowHeight, 1000, 8000);
    camera.position.set(0, 550, 1700);
    const cameraCenter = new THREE.Vector3(0,100,0);
    camera.lookAt( cameraCenter );
    camera.updateProjectionMatrix()

    // lights
    scene.add(new THREE.AmbientLight(0xEEEEEE));
    const light = new THREE.DirectionalLight(0xEEEEEE, 0.73);
    light.position.set(0, windowWidth/2, 0);
    light.position.multiplyScalar(1.5);
    light.castShadow = true;
    light.shadow.mapSize.width = 6000;
    light.shadow.mapSize.height = 6000;
    const d = 6000;
    light.shadow.camera.left = - d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.far = 2000;
    scene.add(light);

    // include objects to scene
    let objPos = 0;
    const availCountries = data.allCountriesJson.edges.filter(edge => edge.node.inThreeCanvas === true)
    // console.log("TCL: ThreeFlag -> availCountries", availCountries)

    for(let i = 0; i < amountObjects; i++){
      // cloth material
      const imgSize = {
        width: restDistance * xSegs,
        height: restDistance * ySegs
      };

      const img = document.createElement("img");
      img.width = imgSize.width;
      img.height = imgSize.height;
      img.src = `${availCountries[i].node.flagPng.publicURL}`
      img.setAttribute("src", `${availCountries[i].node.flagPng.publicURL}` );
      img.setAttribute("width", `${imgSize.width}px` );
      img.setAttribute("height", `${imgSize.height}px` );

      img.onload = () => {
        const texture = new THREE.Texture(img);
        texture.needsUpdate = true;
        texture.anisotropy = 16;

        const clothMaterial = new THREE.MeshStandardMaterial({
          map: texture,
          side: THREE.DoubleSide,
          alphaTest: 0.5
        });
        const object = new THREE.Mesh(clothGeometry, clothMaterial);

        const sizeFactor = windowWidth > 1024 ? 1600 : 950;

        object.position.set(
          Math.sin( ((objPos + 250) * 2 * Math.PI) / amountObjects ) * 1.3 * sizeFactor,
          0,
          Math.cos( ((objPos + 250) * 2 * Math.PI) / amountObjects ) * 1.3 * sizeFactor
          );

        let box = new THREE.Box3().setFromObject( object );
        const sizeVector = new THREE.Vector3();
        const size = box.getSize(sizeVector);

        objects.push( object );

        const cylRadius = 7;
        const cylHeight = windowHeight;
        const cylRadialSegments = 10;

        const geometry = new THREE.CylinderBufferGeometry( cylRadius, cylRadius, cylHeight, cylRadialSegments );
        const material = new THREE.MeshStandardMaterial( {color: 0x111111} );
        const cylinder = new THREE.Mesh( geometry, material );
        cylinder.position.z = -size.z/2;
        cylinder.position.x = -size.x/2;
        cylinder.position.y = 1.5 * size.y - cylHeight/2;
        cylinder.castShadow = true;

        // cone
        const coneHeight = 150;
        const coneRadius = 20;
        var coneGeometry = new THREE.ConeGeometry( coneRadius, coneHeight, 32 );
        var coneMaterial = new THREE.MeshBasicMaterial( {color: 0x222222} );
        var cone = new THREE.Mesh( coneGeometry, coneMaterial );
        cone.position.z = -size.z/2;
        cone.position.x = -size.x/2;
        cone.position.y = -windowHeight/4 + (coneHeight/2);
        cone.castShadow = true;
        object.add( cone );

        object.add(cylinder);
        scene.add(object);
        cylinders.push( cylinder );
        box = null;

        objPos = objPos + 1
      };

      // cloth geometry
      clothGeometry = new THREE.ParametricBufferGeometry(clothFunction, cloth.w, cloth.h);
      

    } // end for loop


    const floorMaterial = new THREE.MeshStandardMaterial({
      color: 0xEEEEEE
    });

    const segments = 2;
    // const size = windowWidth > windowHeight ? windowWidth * 6 : windowHeight * 6;
    const size = windowWidth > 1200 ? 10000 : 8000;
    const floorGeometry = new THREE.CubeGeometry(size, 5, size,segments,segments,segments);
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.position.set(0,-windowHeight/4,0)
    floor.receiveShadow = true;

    scene.add(floor);

    // renderer
    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    renderer.setClearColor( 0x000000, 0 );
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(windowWidth, windowHeight);
    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;
    mount.current.appendChild(renderer.domElement)

    // // Interactions
    // const interaction = new Interaction(renderer, scene, camera);

    // // controls
    // // docs: https://threejs.org/docs/#examples/en/controls/OrbitControls
    // const mouseControls = new OrbitControls( camera, renderer.domElement )
    // mouseControls.autoRotate = false;
    // mouseControls.enableDamping = true;
    // mouseControls.dampingFactor = 0.08;
    // mouseControls.enableZoom = false;
    // mouseControls.maxPolarAngle = Math.PI * 0.43;
    // mouseControls.minPolarAngle = Math.PI * 0.43;
		// mouseControls.minDistance = 50;
		// mouseControls.maxDistance = 15000;
    // mouseControls.update()

    const renderScene = () => {
      var p = cloth.particles;

      for ( var i = 0, il = p.length; i < il; i ++ ) {
        var v = p[ i ].position;
        clothGeometry.attributes.position.setXYZ( i, v.x, v.y, v.z );
      }

      clothGeometry.attributes.position.needsUpdate = true;
      clothGeometry.computeVertexNormals();

      renderer.render(scene, camera)
    }

    const handleResize = () => {
      if (typeof window !== `undefined`) {
        
        // only resize if width changed 
        if( windowWidth !== window.innerWidth ){
          windowWidth = window.innerWidth;
          windowHeight = window.innerHeight;

          renderer.setSize(windowWidth, windowHeight)
          camera.aspect = windowWidth / windowHeight
          camera.updateProjectionMatrix()
          renderScene()
          // console.log("resize happened");
        }
        
      }
      
    }
    window.addEventListener('resize', handleResize, false);

    const animate = () => {
      const time = Date.now();
      const windStrengthContainer = Math.sin( time / 500 ) * params.windStrengthAmplifyer;
      const windStrength = windStrengthContainer > 0 ? windStrengthContainer : params.windStrengthAmplifyer/2;

      windForce.set( 200, 0, 200);
      windForce.normalize();
      windForce.multiplyScalar( windStrength );
      simulate( time );

      scene.rotation.y += params.rotationSpeed + (((mouse.x * 4) - camera.rotation.y) * 0.001);

      // if(typeof window !== "undefined"){
      //   const url = window.location.href;
      //   if(url.match(/\?.+/)){
      //     for(let i = 0; i < objects.length; i++){
      //       objects[i].lookAt( camera.position );
      //     }
      //   }
      // }
      

      renderScene();
      frameId = window.requestAnimationFrame(animate);
    }

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate)
      }
      // console.log('started THREE');
    }
    const stop = () => {
      cancelAnimationFrame(frameId)
      frameId = null
      // console.log('stopped THREE');
    }

    start()

    return () => {
      stop()

      if (scene.geometry) {
        // console.log("dispose geometry ", scene.geometry)
        scene.geometry.dispose()
      }

      if (scene.material) {
        if (scene.material.length) {
          for (let i = 0; i < scene.material.length; ++i) {
            // console.log("dispose material ", scene.material[i])
            scene.material[i].dispose()
          }
        }
        else {
          // console.log("dispose material ", scene.material)
          scene.material.dispose()
        }
      }
      for(let i = 0; i < objects.length; i++){
        scene.remove(objects[i]);
        scene.remove(cylinders[i]);
      }

      scene = null
      camera = null
      renderer && renderer.renderLists.dispose()
      mount.current.removeChild(renderer.domElement)
      renderer = null

      window.removeEventListener('resize', handleResize)
      window.removeEventListener('mousemove', onMouseMove)
      // window.removeEventListener('touchmove', onTouchMove)
    }
  }, [])

  return (
    <>
      <div className="three-flag-wrap">
        <div className="three-flag" ref={mount} />
        <div className="main-header">
          <TurbulenceFont />
          <h1>STOP THE MELTDOWN</h1>
          <p className="mt-2 px-2">
            <span>We are living in a state of meltdown.&nbsp;</span>
            <span className="d-md-block">If global warming continues to rise, glaciers will be gone by 2100.&nbsp;</span>
            <span className="d-md-block">And with them, 69% of the world's drinking water.&nbsp;</span>
            <span className="d-md-block mt-md-2">If the glaciers vanish, so will we.</span>
          </p>
          <div id="scroll">
            <div className="down">↓</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThreeFlag

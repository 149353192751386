import React from "react"
import { graphql } from "gatsby"
import Layout from "Containers/Layout/Layout"
import Teaser from "Sections/Teaser/Teaser.jsx"
import ThreeFlag from "Sections/ThreeFlag/ThreeFlag.jsx"
import PageTransitionLink from "UI/PageTransition/PageTransitionLink.jsx"

import SEO from "../components/seo"

const IndexPage = ({data}) => {

  return (
    <Layout>
      <SEO title="Home" />
      <ThreeFlag />
      <Teaser content={data.allCountriesJson.edges} />
      <div className="m-2 data-text-link">
        <PageTransitionLink to="/all-countries-data">
          View All Countries Data
        </PageTransitionLink>
      </div> 
    </Layout>
  )
}

export const query = graphql`
  {
    allCountriesJson {
      edges {
        node {
          title
          slug
          slitScanHorizontal
          flagPng {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
